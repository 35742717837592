var map;
var markers = [];
var locationHTML = 'Could not determine your closest location.';

$( document ).ready(function() {

	// Mobile Nav Toggle
	$('.nav-toggle').click(function() {
		$(this).toggleClass('active');
		$('.navbar').toggleClass('active');
	});

	// FAQs
	$('.faq-question').click(function() {
		var thisFAQ = $(this).parents('.faq');
		var activeFAQ = $(this).parents('.faqs').find('.active');

		// Close the active FAQ
		activeFAQ.find('.faq-answer').slideUp('fast');
		activeFAQ.toggleClass('active', false);

		// Show the clicked FAQ, unless the active FAQ is clicked
		if( thisFAQ[0] != activeFAQ[0] ) {
			thisFAQ.toggleClass('active', true);
			thisFAQ.find('.faq-answer').slideDown('fast');
		}
	});


	// Homepage ABDA Modal Window
	// If the popup was already seen, don't show it for a day
	/*
	if( Cookies.get('abda-popup-seen') != 'yes' ) {

		var abdaPopupContent = $('.abda-popup-section');
		if( abdaPopupContent.length ) {
			$.magnificPopup.open({
				items: {
					src: abdaPopupContent,
					type: 'inline'
				},
				mainClass: 'abda-popup',
				fixedBgPos: true
			});
		}
		// Set a cookie that the popup has been seen. Cookie expires in 7 days
		Cookies.set('abda-popup-seen', 'yes', { expires: 7 });
	}
	*/

	// Close popup button
	$('.close-popup').click(function() {
		$.magnificPopup.close();
	});


	// Container Types - Magnific Popup
	$('.container-button').magnificPopup({
		type: 'inline',
		midClick: true,
		mainClass: 'container-type-popup',
		removalDelay: 200,
		fixedBgPos: true,
		// Disable popup if width is greater than 1023px
		disableOn: function() {
			if( $(window).outerWidth() > 1023 ) {
				return false;
			}
			return true;
		}
	});

	//Container Types - Expanded Rows
	var activeContainerType = $();
	var activeContainerInfoRow = $();

	$('.container-button').click(function() {
		// Only for screen width of 1024px and above
		if( $(window).outerWidth() > 1023 ) {

			// Deactivate the active Container Type and close the active Info Row
			activeContainerType.toggleClass('active', false);
			activeContainerInfoRow.toggleClass('active', false);
			activeContainerInfoRow.slideUp('fast');

			var thisContainerType = $(this).parents('.container-type');

			// Show the clicked Container Type Info, unless the active one is clicked
			if( thisContainerType[0] != activeContainerType[0] ) {
				thisContainerType.toggleClass('active', true);

				var infoId = $(this).data('mfp-src').split('-').pop();
				activeContainerInfoRow = $('#container-info-row-' + infoId);
				activeContainerInfoRow.toggleClass('active', true);
				activeContainerInfoRow.slideDown('fast', function() {
					// On slidedown complete
					// Scroll to the active Container Info
					$.scrollTo( activeContainerInfoRow, 200, {
						offset: {top: -200}
					});
				});

				activeContainerType = thisContainerType;
			} else {
				activeContainerType = $();
			}
		}
	});


	// Slick Carousel
	$('.home-stat-slider-section .blocks').slick({
		slidesToScroll: 1
	});

	$('.applauds-slider').slick({
		slidesToScroll: 1, 
		arrows: false, 
		dots: true, 
	});


	// Sticky nav
	$(document).on("scroll", function() {

		if( $(document).scrollTop() > 48 ) {
			$('header.header').toggleClass('sticky', true);
		} else {
			$('header.header').toggleClass('sticky', false);
		}
	});


	// FitVids JS
	$('.youtube').fitVids();


	// Mobile Sidebar Menu
	$('.sidebar-menu-dropdown').on('change', function(e) {
		location.href = $(this).val();
	});


	// Locate Me
	var coords = null;
	Location.updateLocationsMap(null, null);
	$.when(Location.getMyLocation())
		.then(
			function(data, textStatus, jqXHR) {
				coords = data.split(',');
				Location.updateNearestLocation(coords);
				Location.updateLocationsMap(coords[0], coords[1]);
			},
			function(err) {
				console.log(err);
			}
		);
	

	// if firefox
	if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
		setTimeout(function() {
			if (typeof(Cookies.get('my_current_location')) === 'undefined') {
	        	$('li.closest-depot span').html('<a href="/find-a-location">Click here</a> to find your closest depot.');
                $('li.get-directions').addClass('hidden');
                $('li.closest-depot').addClass('not-found');
			}
		}, 5000);
	}

	// Enter to find a location
	$('#button-enter-to-find-a-location').on('click', function(e) {
		e.preventDefault();
		var val = $(this).parent().find('input').val();
		Location.updateLocationsMap(coords.join(','), 999, val);
	});

	$('#input-enter-to-find-a-location').on('keypress', function(e) {
		if (e.keyCode === 13) {
			Location.updateLocationsMap(coords.join(','), 999, $(this).val());
		}
	});

    // Select a depot to applaud
    sortByProperty = function(property) {
        return function (a, b) {
            var sortStatus = 0,
                aProp = a[property].toLowerCase(),
                bProp = b[property].toLowerCase();
            if (aProp < bProp) {
                sortStatus = -1;
            } else if (aProp > bProp) {
                sortStatus = 1;
            }
            return sortStatus;
        };
    }
    if ($('.applaud-depot-excelence').length) {
        var selectLocation = $('<select>');
        $('.applaud-depot-excelence').html('Loading locations...');

        Location.getAllLocations().then(function(response) {
            var locations = JSON.parse(response).sort(sortByProperty('city'));
            var lastCity = locations[0].city;
            var optGroup = $('<optgroup label="' + lastCity + '">')
            selectLocation.empty().append('<option value="http://www.applaudadepot.ca">Select Depot to Applaud</option>');
            locations.forEach(function(location) {
                if (lastCity !== location.city) {
                    selectLocation.append(optGroup);
                    lastCity = location.city;
                    optGroup = $('<optgroup label="' + lastCity + '">')
                }
                optGroup.append('<option value="' + location.slug + '">' + location.name + ' (' + location.address_line_1 + ', ' + location.city + ')</option>')
            });
            $('.applaud-depot-excelence').html(selectLocation);
            $('.applaud-depot-excelence select')
                .select2()
                .on('select2:select', function (e) {
                    $('.accept-button.applaud-a-depot')
                    	.attr('href', '/find-a-depot' + '#applaud-' + e.params.data.id)
                    	.attr('target', '_parent');
                })
            setTimeout(function() {
            	$('.select2-container').css('width', '100%');
            }, 200);
        });
    }

    // Resize Find a Depot iFrame
    var deviceHeight = Math.min( $(window).height() - 60, 700 );
    $('#find-a-location').height( deviceHeight );
});

$( window ).load(function() {

	// Update jQuery Match Height
	$.fn.matchHeight._update();
});
