var Location = {
	geoDeferred: $.Deferred(),
	map: null,
	markers: [],
	locationHTML: 'Could not determine your closest location.',
	cookieExpireinDays: 1,

	getMyLocation: function() {
		if(navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(this.geoLocationFound, this.geoLocationError, {
                enableHighAccuracy:true,
                timeout:5000,
                maximumAge:1800000
            });
        }
        else {
        	$('li.closest-depot span').html('<a href="/find-a-location">Click here</a> to find your closest depot.');
            $('li.closest-depot .get-directions').addClass('hidden');
            $('li.get-directions').addClass('not-found');
		    console.log('Your browser does not support Geo Location.');
        }

        return Location.geoDeferred.promise();
    },

	geoLocationStatic: function () {
		var staticDeferred = $.Deferred();
		$.ajax({
			url: '/api/my_location'
		}).done(function(response) {
			var position = JSON.parse(response);
			if (!location.error) {
				Cookies.set('my_current_location', position.lat + "," + position.lon, { expires: Location.cookieExpireinDays });
				staticDeferred.resolve(Cookies.get('my_current_location'));
			}
		}).fail(function(err) {
			staticDeferred.reject(error);
		});
		return staticDeferred.promise();
	},

	geoLocationFound: function (position) {
		var my_location_cookie = Cookies.get('my_current_location');
		var lat_lon = (my_location_cookie ? my_location_cookie.split(",") : []);
		var myLocation = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
		Cookies.set('my_current_location', position.coords.latitude + "," + position.coords.longitude, { expires: Location.cookieExpireinDays } );
		Location.geoDeferred.resolve(Cookies.get('my_current_location'));
	},

	geoLocationError: function (err) {
		$.ajax({
			url: '/api/my_location'
		}).done(function(response) {
			var position = JSON.parse(response);
			if (!location.error) {
				Cookies.set('my_current_location', position.lat + "," + position.lon, { expires: Location.cookieExpireinDays } );
				Location.geoDeferred.resolve(Cookies.get('my_current_location'));
			}
		}).fail(function(err) {
			Location.geoDeferred.reject(error);
		});
	},

	getNearesetLocation: function(latitude, longitude) {
    	var deferred = $.Deferred();
    	$.ajax({
			url: '/api/nearest_depot/' + latitude + '/' + longitude
		}).done(function(response) {
			deferred.resolve(response);
		}).fail(function(err) {
			deferred.reject(error);
		});
    	return deferred.promise();
    },

    getAllLocations: function(latitude, longitude, limit, searchVal) {
        basePath = '/api/all_depots';
        if (typeof(latitude) !== 'undefined' && typeof(longitude) !== 'undefined') {
            basePath += '/' + latitude + '/' + longitude + '?limit=' + limit + (typeof(searchVal) === 'undefined' || searchVal.length === 0 ? '' : '&search=' + encodeURI(searchVal))
        }

    	var deferred = $.Deferred();
    	$.ajax({
			url: basePath
		}).done(function(response) {
			deferred.resolve(response);
		}).fail(function(err) {
			deferred.reject(error);
		});
    	return deferred.promise();
    },

	directions: function(start, destination) {
		return 'https://www.google.com/maps?saddr=' + start + '&daddr=' + destination;
	},

    distance: function(lat1, lon1, lat2, lon2) {
		var p = 0.017453292519943295;
		var c = Math.cos;
		var a = 0.5 - c((lat2 - lat1) * p)/2 + c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))/2;
		return Math.round(12742 * Math.asin(Math.sqrt(a)) * 100)/100;
	},

    updateNearestLocation: function(coords) {
		if(Cookies.get('nearest_location') && Cookies.get('my_current_location') === coords.join(',')) {
			$('li.closest-depot span').html(Cookies.get('nearest_location'));
			$('.get-directions a')
				.attr('target', '_blank')
				.attr('href', Location.directions(Cookies.get('my_current_location'), Cookies.get('nearest_location_coords')));
		}
		else {
			$.when(Location.getNearesetLocation(coords[0], coords[1]))
				.then(function(data) {
					var nearestLocation = JSON.parse(data);

					if (Object.keys(nearestLocation).length > 0) {
						locationHTML = [nearestLocation.name, nearestLocation.address_line_1, nearestLocation.phone].join(' - ');
						Cookies.set('nearest_location', locationHTML, { expires: Location.cookieExpireinDays });
						Cookies.set('nearest_location_coords', nearestLocation.lat + ',' + nearestLocation.lng);
						$('.get-directions a')
							.attr('target', '_blank')
							.attr('href', Location.directions(Cookies.get('my_current_location'), Cookies.get('nearest_location_coords')));
					}
					else {
						$('.get-directions').hide();
					}

					$('li.closest-depot span').html(locationHTML);
				});
		}
	},

	updateLocationsMap: function(lat, lng) {
		if (location.hash && lat !== null && lng !== null) {
			$('#find-a-location').attr('src', 'https://www.findadepot.ca/' + location.hash.replace('#', '').replace('applaud-', '')
				+ '?lat=' + lat + '&lng=' + lng + '#applaud');
		} else if (location.hash) {
			console.log('https://www.findadepot.ca/' + location.hash.replace('#', '').replace('applaud-', '') + '#applaud');
			$('#find-a-location').attr('src', 'https://www.findadepot.ca/' + location.hash.replace('#', '').replace('applaud-', '') + '#applaud');
		} else {
			$('#find-a-location').attr('src', 'https://www.findadepot.ca/?lat=' + lat + '&lng=' + lng);
		}
	},

	onLocationSelect: function(index) {
		var selectedMarker = Location.markers[index];
		google.maps.event.trigger(selectedMarker, 'click');
		Location.map.setCenter(selectedMarker.getPosition());
	},

	showLocationDetails: function(slug, link) {
		var location = {};

		$('.cta-more-details').find('i').show();
		$.ajax('/api/get_depot/' + slug)
			.done(function(res) {
				location = JSON.parse(res);
				$.magnificPopup.open({
					items: { src: '#open-location-popup' },
					type: 'inline',
					callbacks: {
					    open: function() {
					    	$('.cta-more-details').find('i').hide();
					    	$('#open-location-popup .applauds-list').empty();
                            var isDraggable = $(document).width() > 480 ? true : false;

							var popupMap = new google.maps.Map(document.getElementById('popup-map'), {
								center: { lat: parseFloat(location.lat), lng: parseFloat(location.lng) },
								zoom: 14,
                                draggable: isDraggable,
                                scrollwheel: false
							});

							$('#open-location-popup .applauds .link a').attr('href', link + '#applaud');
							$('#open-location-popup h2').html(location.name);
							$('#open-location-popup #popup-location')
								.html(location.address_line_1 + '<br />' + location.city + ', ' + location.province +
									'<br /><strong>' + location.phone + '</strong><br /><br /><p>Please contact the depot for hours of operation.</p>' +
									'<a target="_blank" href="' + Location.directions(Cookies.get('my_current_location'), [location.lat, location.lng].join(',')) + '" class="cta-more-details">GET DIRECTIONS</a>'
								);


							$('#open-location-popup .description').empty();
							if (location.depot_description) {
								$('#open-location-popup .description').html(
									'<p>' + location.depot_description + '</p>'
								);
							}

							if(location.hasOwnProperty('applauds') && location.applauds.length > 0) {
								location.applauds.map(function(applaud) {
									var date = new Date(applaud.created_at);
									$('#open-location-popup .applauds-list').append(
										'<p>' +
											applaud.original_applaud +
											'<strong>' + applaud.first_name + ' - ' + date.toDateString() + '</strong>' +
										'</p>'
									);
								});
							}
							else {
								$('#open-location-popup .applauds-list').append('<p>There are no applauds for this location.</p>')
							}

							var marker = new google.maps.Marker({
					        	position: new google.maps.LatLng(location.lat, location.lng),
					        	map: popupMap,
					        	icon: '/themes/custom/img/icon-location-marker.png'
					      	});

						}
					}
				});
			});
	}
};